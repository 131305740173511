import React from "react"
import Layout from "../../components/layout"
import 'swiper/css/swiper.css';
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import PageIntro from "../../components/pageintro"
import { Link } from "gatsby"
import SliderDestinations from "../../components/slider-destinations"
import ListRegions from "../../components/list-regions"

const DestinationsPage = ({ data }) => (
  <Layout>
    <SEO title="The Staycation Destinations" description="Never been beyond your house and the airport? Don't worry we've got you sorted." />
    <h1>Find the best places to visit in the UK</h1>
    <div style={{ marginBottom: `1.45rem` }}>
    <PageIntro>
    <h3>The UK really does have it all.</h3>
    <p>We've got landscapes galore, from tall snowy mountains, to long sandy beaches, and everything in between.</p>
    <p>And for shopping, dining, history or nights out, all around the UK we have fascinating villages, towns and cities.</p>
    <p>Browse these {data.allMarkdownRemark.totalCount} destinations below</p>
    </PageIntro>
    <ul>
    {data.allMarkdownRemark.edges.map(({ node }) => (
          <li>
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </li>
        ))}
    </ul>
    </div>
    <SliderDestinations/>
    <h2>View all regions:</h2>
    <ListRegions />
  </Layout>
)

export default DestinationsPage

export const query = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "destination"}}}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
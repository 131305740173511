/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

export default function Pageintro({ children }) {

  return (
    <div class="introbox">{children}</div>
  )
}

import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const ListRegions = ({ data }) => (
    <ul>
      {data.allTownsCsv.group.map(({ fieldValue }) => (
      <li><Link to={fieldValue.toLowerCase()}>{fieldValue.replace(/-/g,' ')}</Link></li>
        ))}
      </ul>
)

export default function MyListRegions () {
    return (

  <StaticQuery
    query={graphql`
    {
      allTownsCsv {
        group(field: Region) {
          fieldValue
        }
      }
    }
    `} 
    render={data => <ListRegions data={data} />}
  />
)
}

